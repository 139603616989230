import { Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { AuthGuard } from '@common/guards/auth-guard.service';
import { ContactComponent } from '@common/contact/contact.component';
import { LinkPreviewHostComponent } from './shared/link-preview/link-preview-host/link-preview-host.component';
import { NOT_FOUND_ROUTES } from '@common/core/pages/not-found-routes';
var ɵ0 = { willSetSeo: true }, ɵ1 = function () { return import("./dashboard/dashboard.module.ngfactory").then(function (m) { return m.DashboardModuleNgFactory; }); }, ɵ2 = function () { return import("./admin/app-admin.module.ngfactory").then(function (m) { return m.AppAdminModuleNgFactory; }); }, ɵ3 = function () { return import("./../common/billing/billing.module.ngfactory").then(function (m) { return m.BillingModuleNgFactory; }); }, ɵ4 = { willSetSeo: true };
var routes = [
    { path: '', pathMatch: 'full', component: LandingComponent, data: ɵ0 },
    { path: 'dashboard', loadChildren: ɵ1, canLoad: [AuthGuard] },
    { path: 'admin', loadChildren: ɵ2, canLoad: [AuthGuard] },
    { path: 'billing', loadChildren: ɵ3, canLoad: [AuthGuard] },
    { path: 'contact', component: ContactComponent },
    {
        path: '**',
        pathMatch: 'full',
        component: LinkPreviewHostComponent,
        data: ɵ4,
    }
].concat(NOT_FOUND_ROUTES);
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
