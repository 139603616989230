import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '@common/core/services/local-storage.service';
import { Settings } from '@common/core/config/settings.service';
import { slugifyString } from '@common/core/utils/slugify-string';
import * as i0 from "@angular/core";
import * as i1 from "./services/local-storage.service";
import * as i2 from "./config/settings.service";
var ThemeService = /** @class */ (function () {
    function ThemeService(localStorage, settings) {
        this.localStorage = localStorage;
        this.settings = settings;
        this.selectedTheme$ = new BehaviorSubject(null);
        this.rootEl = document.documentElement;
    }
    ThemeService.prototype.registerThemes = function (themes) {
        this.registeredThemes = themes;
        if (!this.selectedTheme$.value) {
            this.select(this.getPreferredTheme());
        }
    };
    ThemeService.prototype.select = function (name) {
        var _this = this;
        var theme = this.registeredThemes[name];
        this.selectedTheme$.next(theme);
        this.setPreferredTheme(name);
        if (theme.is_dark) {
            this.rootEl.classList.add('be-dark-mode');
        }
        else {
            this.rootEl.classList.remove('be-dark-mode');
        }
        Object.entries(theme.colors).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            _this.rootEl.style.setProperty(key, value);
        });
    };
    ThemeService.prototype.toggle = function () {
        if (this.selectedTheme$.value.is_dark) {
            this.select('light');
        }
        else {
            this.select('dark');
        }
    };
    ThemeService.prototype.getPreferredTheme = function () {
        return this.localStorage.get(this.storageKey()) || 'light';
    };
    ThemeService.prototype.setPreferredTheme = function (name) {
        this.localStorage.set(this.storageKey(), name);
    };
    ThemeService.prototype.storageKey = function () {
        return slugifyString(this.settings.get('branding.site_name')) + '.theme';
    };
    ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(i2.Settings)); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
